<template>
  <v-app>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty, has } from 'lodash'

export default {
  name: 'OidcCallback',
  data() {
    return {
      overlay: true
    }
  },
  computed: {
    ...mapGetters(['user', 'scores'])
  },
  methods: {
    ...mapActions(['signinCallback', 'loadSystem'])
  },
  mounted() {
    if (sessionStorage.state !== this.$route.query.state) {
      this.$router.push({ name: 'home' })
    }
    // call api to get token
    this.signinCallback({
      code: this.$route.query.code,
      nonce: sessionStorage.nonce
    }).then((result) => {
      if (result) {
        this.loadSystem().then((_) => {
          if (sessionStorage.login === 'signUp' && sessionStorage.campaign) {
            this.$router.push({ name: 'signUp' })
          } else {
            if (this.user?.type?.toLowerCase() === 'staff') {
              if (has(this.user, 'blocked') && !this.user.blocked) {
                this.$router.push({ name: 'settings' })
              } else {
                this.$router.push({ name: 'unauthorized' })
              }
            } else {
              if (!isEmpty(this.scores)) {
                this.$router.push({ name: 'dashboard' })
              } else {
                this.$router.push({ name: 'unauthorized' })
              }
            }
          }
        })
      } else {
        this.$router.push({ name: 'home' })
      }
    })
  }
}
</script>

<style>
body {
  background: #121212 !important;
}
</style>
